.rose-wrapper {
  width: 100%;
  text-align: center;
  margin-top: 40px;

  .signature, .slogan {
    color: #808080;
  }
  
  .image-wrapper {
    .img-thumbnail {
      width: 300px;
    } 

    .signature {
      font-size: 0.9em;
      font-style: italic;
      width: 360px;
      display: inline-block;
      text-align: right;
    }
  }

  .slogan {
    margin-top: 30px;
    margin-bottom: 40px;
  }
}